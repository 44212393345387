import React from "react"
import ButtonSign from "../components/button-sign"
const close = require("../images/icons/dp-close-white.svg")

export default function VideoOverlay(props) {
  return (
    <div
      id={props.elementId}
      className={`c-video-overlay ${
        props.show ? "c-video-overlay--show" : "c-video-overlay--hide"
      }`}
      aria-hidden={!props.show}
    >
      <button
        className="c-video-overlay__close c-button c-button--close"
        onClick={props.toggleShow}
        aria-controls={props.elementId}
      >
        <span className="c-button__text">Stäng</span>
        <img className="c-button__img" src={close} />
      </button>
      <div className="c-video-overlay__content">
        <div className="row c-video-overlay__caption">
          <div className="col-xs-12 col-md-7">{props.caption}</div>
          <div className="col-xs-12 col-md-5">
            {props.signUrl && (
              <ButtonSign to={props.signUrl} text="Svenskt teckenspråk" />
            )}
          </div>
        </div>
        {props.show && (
          <div className="c-video-overlay__embed">
            <iframe
              src={props.url}
              id="vimeoplayer"
              frameborder="0"
              webkitAllowFullScreen
              mozallowfullscreen
              allowFullScreen
            ></iframe>
          </div>
        )}
      </div>
    </div>
  )
}
