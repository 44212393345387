import * as React from "react"

import ButtonPlay from "../components/button-play"
import ButtonPurple from "../components/button-purple"
import ButtonOutline from "../components/button-outline"

const ImageTeaser = props => {
  return (
    <div
      className={
        props.modifier
          ? "c-image-teaser c-image-teaser--" + props.modifier
          : "c-image-teaser"
      }
    >
      <div className="c-image-teaser__image">
        <div className="c-image-teaser__image__mask">
          <img src={props.img} alt={props.imgAlt} />
        </div>
      </div>
      <div className="c-image-teaser__content">
        {props.title && props.modifier == "small" && (
          <h3 className="c-image-teaser__title">{props.title}</h3>
        )}

        <div className="c-image-teaser__text">{props.children}</div>

        {props.listheading && props.segments && <h4>{props.listheading}</h4>}

        {props.segments && (
          <ul className="c-image-teaser__list">
            {props.segments.map((segment, index) => (
              <li key={index} className="c-image-teaser__list__item">
                {segment.text}
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="c-image-teaser__action">
        {props.video && props.onClick && props.buttonText && (
          <ButtonPlay
            text={props.buttonText}
            onClick={props.onClick}
            ariaControls={props.ariaControls}
          />
        )}

        {props.to && props.linkButtonText && (
          <ButtonPurple text={props.linkButtonText} to={props.to} />
        )}

        {props.qr && props.onClick && props.buttonText && (
          <ButtonOutline
            text={props.buttonText}
            onClick={props.onClick}
            ariaLabel={props.buttonText + " till " + props.title}
          />
        )}
        {props.extraButtonText && (
          <ButtonPurple text={props.extraButtonText} to={props.extraButtonTo} />
        )}
        {props.datetime && (
          <p>
            <span className="c-description">{props.datetime}</span>
          </p>
        )}
      </div>
    </div>
  )
}

export default ImageTeaser
