import * as React from "react"
import { useState, useEffect } from "react"
import { Helmet } from "react-helmet"

import Breadcrumbs from "../../../components/breadcrumbs"
import Hero from "../../../components/hero"
import ImageTeaser from "../../../components/image-teaser"
import PageHeader from "../../../components/page-header"
import SectionHeader from "../../../components/section-header"
import Footer from "../../../components/footer"
import VideoOverlay from "../../../components/video-overlay"
import QROverlay from "../../../components/qr-overlay"

const videoThumb = require("../../../images/vad-ar-rattvisa/@2xdp-img-vadarrattvisa-puff.jpg")
const exerciseThumb = require("../../../images/vad-ar-rattvisa/vadarrattvisa-pizza.png")

import "../../../scss/site.scss"
//import StickyCta from "../../../components/sticky-cta"

const WhatIsJustice = props => {
  const [showOverlay, setShowOverlay] = useState(false)
  const [caption, setCaption] = useState(null)
  const [url, setUrl] = useState(null)
  const [signUrl, setSignUrl] = useState(null)

  useEffect(() => {
    if (showOverlay) {
      document.body.style.overflowY = "hidden"
    } else {
      document.body.style.overflowY = ""
    }
    return () => {
      document.body.style.overflowY = ""
    }
  }, [showOverlay])

  const handleShowOverlay = (caption, url, signUrl) => {
    if (caption) setCaption(caption)
    if (url) setUrl(url)
    if (signUrl) setSignUrl(signUrl)
    setShowOverlay(!showOverlay)
  }

  const [showQrOverlay, setShowQrOverlay] = useState(false)
  const [heading, setHeading] = useState(null)
  const [text, setText] = useState(null)
  const [linkText, setLinkText] = useState(null)
  const [imgUrl, setImgUrl] = useState(null)

  useEffect(() => {
    if (showQrOverlay) {
      document.body.style.overflowY = "hidden"
    } else {
      document.body.style.overflowY = ""
    }
    return () => {
      document.body.style.overflowY = ""
    }
  }, [showQrOverlay])

  const handleShowQrOverlay = (heading, text, linkText) => {
    if (heading) setHeading(heading)
    if (text) setText(text)
    if (linkText) setLinkText(linkText)
    setShowQrOverlay(!showQrOverlay)
  }

  const videoOverlayId = "demokratiska-samtal-video-overlay"

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Vad Är Rättvisa | Demokrati Pågår </title>
        <link
          rel="canonical"
          href="https://www.demokratipagar.se/demokratiska-samtal/vad-ar-rattvisa"
        ></link>
        <meta property="og:site_name" content="Demokrati Pågår"></meta>
        <meta property="og:type" content="article"></meta>
        <meta
          property="og:url"
          content="https://www.demokratipagar.se/demokratiska-samtal/vad-ar-rattvisa"
        ></meta>
        <meta property="og:title" content="Vad Är Rättvisa"></meta>
        <meta name="description" content="" />
        <script
          type="text/javascript"
          src="https://www.browsealoud.com/plus/scripts/3.1.0/ba.js"
          crossorigin="anonymous"
          integrity="sha256-VCrJcQdV3IbbIVjmUyF7DnCqBbWD1BcZ/1sda2KWeFc= sha384-k2OQFn+wNFrKjU9HiaHAcHlEvLbfsVfvOnpmKBGWVBrpmGaIleDNHnnCJO4z2Y2H sha512-gxDfysgvGhVPSHDTieJ/8AlcIEjFbF3MdUgZZL2M5GXXDdIXCcX0CpH7Dh6jsHLOLOjRzTFdXASWZtxO+eMgyQ=="
        ></script>
      </Helmet>
      <div className="c-body">
        <PageHeader />
        <div
          role="main"
          id="main"
          className="o-main c-main-background c-main-background--demokratiska-samtal"
        >
          <Breadcrumbs
            modifier="indent"
            crumbs={props.pageContext.breadcrumb}
          />
          <Hero
            buttonLink="handledning"
            buttonText="Till läraren"
            modifier="has-breadcrumbs"
            title="Vad är rättvisa?"
          >
            <p>
              När vi ska dela någonting vill vi ofta att det ska bli rättvist.
              Men vad är egentligen en rättvis fördelning? På den frågan kan det
              finnas flera svar. Vi kan till exempel dela efter behov eller
              efter förtjänst. Eller så kan vi tänka att det är mest rättvist
              att dela precis lika. Finns det andra sätt att fördela rättvist?
              Kan det vara olika i olika situationer?
            </p>
            <p>
              Lyssna på berättelsen <em>Pizzan och rättvisan</em> och fördela
              pizzan. Du kan dela övningen med dina elever
            </p>
          </Hero>
          <section
            className="c-content-section"
            aria-labelledby="lyssna-pa-berattelsen"
          >
            <SectionHeader
              id="lyssna-pa-berattelsen"
              text="Lyssna på berättelsen"
            />
            <div className="container c-content-section__container">
              <div className="c-content-section__row row">
                <div className="col-xs-12">
                  <div className="c-content-section__content c-content-section__content--small">
                    <ImageTeaser
                      video="yes"
                      modifier="small"
                      img={videoThumb}
                      imgAlt=""
                      title="Pizzan och rättvisan"
                      buttonText="Lyssna på berättelsen"
                      datetime="Ca 5 min"
                      onClick={() =>
                        handleShowOverlay(
                          "Pizzan och rättvisan bygger på en berättelse av Ylva Backman, Luleå tekniska universitet.",
                          "https://player.vimeo.com/video/521293866?api=1&player_id=vimeoplayer&title=0&byline=0&portrait=0&color=0BA126",
                          "https://vimeo.com/527306762"
                        )
                      }
                      ariaControls={videoOverlayId}
                    >
                      <p>Berättelse och rättvisedilemma.</p>
                      <p>
                        <em>Pizzan och rättvisan</em> bygger på en berättelse av
                        Ylva Backman, Luleå tekniska universitet.
                      </p>
                    </ImageTeaser>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section
            className="c-content-section"
            aria-labelledby="ovning-dela-pizzan"
          >
            <SectionHeader id="ovning-dela-pizzan" text="Övning: Dela pizzan" />
            <div className="container c-content-section__container">
              <div className="c-content-section__row row">
                <div className="col-xs-12">
                  <div className="c-content-section__content c-content-section__content--small">
                    <ImageTeaser
                      qr="yes"
                      modifier="small"
                      img={exerciseThumb}
                      imgAlt=""
                      title="Övning: Dela pizzan"
                      to="pizza"
                      linkButtonText="Till övningen"
                      buttonText="Länk och QR-kod"
                      extraButtonText="Utskriftsversion"
                      extraButtonTo="https://admin.demokratipagar.se/wp-content/uploads/2021/04/Pizzan.pdf"
                      onClick={() => {
                        handleShowQrOverlay(
                          "Övning: Dela pizzan",
                          "Öppna med QR-kod eller skriv in länk:",
                          "bit.ly/pizzaövning"
                        )
                      }}
                      ariaControls={videoOverlayId}
                    >
                      <p>
                        Låt dina elever lösa rättvisedilemmat från berättelsen.
                        Du kan också välja att skriva ut övningen
                      </p>
                    </ImageTeaser>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
        <VideoOverlay
          elementId={videoOverlayId}
          show={showOverlay}
          caption={caption}
          url={url}
          signUrl={signUrl}
          toggleShow={() => setShowOverlay(!showOverlay)}
        />
        {showQrOverlay && (
          <QROverlay
            show={showQrOverlay}
            heading={heading}
            text={text}
            linkText={linkText}
            imgUrl={imgUrl}
            toggleShow={() => setShowQrOverlay(!showQrOverlay)}
          />
        )}
        {/*<StickyCta />*/}
      </div>
    </>
  )
}

export default WhatIsJustice
