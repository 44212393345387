import React from "react"
import Link from "gatsby-link"
import { propTypes } from "qrcode.react"
const playIcon = require("../images/icons/dp-icon-play.svg")

export default function ButtonPlay(props) {
  return (
    <button
      onClick={props.onClick}
      className="c-button c-button--purple c-button--play"
      activeclassname="-active"
      aria-controls={props.ariaControls || ""}
    >
      <span className="c-button__text">{props.text}</span>
      <img className="c-button__img" src={playIcon} alt="" />
    </button>
  )
}
